@import '../../../variables';

.header {
  border-top-left-radius: $box-radius;
  border-top-right-radius: $box-radius;
  background-color: $primary-light;
  padding-top: 2rem;
}

.container {
  border-radius: $box-radius;
  padding-bottom: 2rem;
}
