.filtersSection {
  padding: 1.5rem 0 1.5rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tableColumn {
  max-width: 100%;
}