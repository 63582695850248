.container {
  background-color: white;
  height: 100vh;
  padding-top: 30vh;
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
}

.icon {
  margin-bottom: 30px;
  height: 64px;
  width: 64px;

  svg {
    height: 64px;
    width: 64px;
  }
}