@import '../../../variables';

.avatar {
  height: 24px;
  width: 24px;
  background-color: hsla(44, 100%, 58%, 1);
  border-radius: 70px;
  font-size: 12px;
}

.chevronIcon {
  height: 12px;
  width: 12px;
}
