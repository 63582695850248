@import 'node_modules/bulma/sass/utilities/initial-variables.sass';

.container {
  height: 100%;
  overflow-y: auto;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: $white;
  border-top: $grey-lighter 1px solid;
  cursor: pointer;
  margin-top: auto;
  height: 57px !important;
}

.fade {
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 100px !important;
  margin-top: auto;
  pointer-events: none;
  flex-shrink: 0;

  &.footerFade {
    bottom: 57px;
  }
}
