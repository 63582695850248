@import '../../../variables';

.customCheckbox {
  accent-color: $primary-dark;
  position: relative;
}

.customCheckbox:indeterminate::before {
  content: '';
  display: block;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  background-color: $primary-dark;
}

.customCheckbox:indeterminate::after {
  content: '';
  display: block;
  width: 7px;
  height: 2px;
  border: solid white;
  border-width: 2px 0 0 0;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 4px);
}
