@import '../../../variables';

$border-radius: 8px;
$border-weigth: 1px;

.layout {
  display: flex;
  overflow: hidden;
}

.borderedLayout {
  border: solid $primary $border-weigth;
  border-radius: $border-radius;
}

.doubleColumnLayout {
  height: 550px;
}

.singleColumnLayout {
  max-height: 550px;
}

.singleColumn {
  max-height: 550px;
  overflow-x: auto;
}

.leftColumn,
.rightColumn {
  height: 100%;
}

.rightColumn {
  overflow-x: auto;
}

.leftColumn {
  border-right: solid $primary $border-weigth;
}
