@import '../../../variables';

.appContainer {
  min-height: 104px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background-color: $primary-light;
  }
}

.active {
  background-color: $primary-light;
}
