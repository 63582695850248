@import '../../../variables';

.navbar {
  padding-inline: 3rem;
  padding-block: 0.5rem;
  background-color: white;
}

.item {
  border-radius: 6px;
}

.appLauncher {
  width: 400px;
}

.profileContainer {
  background-color: $primary-light;
}
