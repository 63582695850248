@import 'node_modules/bulma/sass/utilities/initial-variables.sass';

.triggerButton {
  display: inline-flex;
  align-items: center;
}

.dropdownMenuContainer {
  width: max-content;
}

.clickableContent > div {
  cursor: pointer;
  color: $grey-dark;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

.clickableContent > div:hover {
  background-color: $white-ter;
  color: $black;
}
