@import '../../../variables';

.actionButton {
  height: 60px;
  width: 510px;
  border-radius: 16px;
  border: none;
  background-color: $primary-dark;
  transition: background-color 0.25s ease, box-shadow 0.25s ease;

  &:hover {
    background-color: $primary;
    box-shadow: 0px 0px 16px rgba($primary, 0.6);
  }
}

.buttonIcon {
  color: white;
  height: 19px !important;
}
