.title {
  line-height: 38px;
}

.email {
  line-height: 24px;
}

.managementHubIcon {
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 8px;
}

.actionButton {
  height: 60px;
  width: 510px;
  border-radius: 16px;
  background: linear-gradient(89.99deg, #267abf -7.64%, #123959 0.43%);
}

.buttonIcon {
  color: white;
  height: 19px !important;
}
