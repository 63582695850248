@import 'node_modules/bulma/sass/utilities/functions.sass';
@import 'node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../../../variables';

$border-radius: 8px;

.container {
  width: 25rem;
  box-shadow: none;
  border: 1px solid $grey-light;
  border-radius: $border-radius;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  height: 100%;

  &.fullWidth {
    width: 100%;
  }

  &.active {
    border: 2px solid $primary;
    box-shadow: 0px 7px 6px -3px hsla(198, 47%, 43%, 0.25);
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);
  }

  &.activeError {
    border: 2px solid $danger-dark;
    box-shadow: 0px 7px 6px -3px hsla(198, 47%, 43%, 0.25);
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

.background {
  background-color: $primary-light;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.errorBackground {
  background-color: findLightColor($danger);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
