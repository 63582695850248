.content {
  line-break: anywhere;
}

.informationColumn {
  height: fit-content !important;
  max-width: 100%;
}

.tablesColumn {
  max-width: 100%;
}
