.warning {
  @media (min-width: 768px) {
    display: none;
  }
}

.content{
  display: none;
  @media (min-width: 768px) {
    display: unset;
  }
}