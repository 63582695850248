@import '../../../variables';
@import '~bulma/sass/utilities/derived-variables';

.box {
  border-radius: 0px;
  min-height: calc(100vh - 60px);
  height: 100%;
  
  @media (min-width: 768px) {
    height: auto;
    min-height: 80vh;
    margin: 1rem 4rem 1rem 4rem;
    border-radius: 30px;
  }
}

.headerWrapper {
  border-radius: 0px;

  @media (min-width: 768px) {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
}

.header {
  @media (min-width: 768px) {
    padding: 1rem;
  }
}

.title {
  font-size: 1.5rem;
  font-weight: 300;
  color: $primary-dark;
  justify-self: center;

  @media (min-width: 768px) {
    justify-self: auto;
    font-size: 2rem;
  }
}

.instructionsText {
  font-size: 1rem;
  font-weight: 300;
  color: $primary-dark;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 0.875rem;
    text-align: start;
  }
}

.eulaDocument {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 55vh;
  overflow-y: scroll;
  ol {
    list-style-position: inside;
  }

  @media (min-width: 768px) {
    height: 40vh;
  }
}

.eulaPdf {
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow: visible;
  padding: 80px;
  width: 1068px;
  word-wrap: break-word;
}

.actionsWrapper {
  padding: 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.checkIcon {
  height: 64px;
  width: 64px;
  color: $success-dark;
  

  svg {
    height: 64px;
    width: 64px;
  }
}