@import '~bulma/sass/utilities/functions';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$primary: hsla(207, 67%, 45%, 1);
$primary-dark: hsla(207, 66%, 29%, 1);
$primary-light: findLightColor($primary);

$danger: hsla(348, 100%, 61%, 1);
$danger-dark: hsla(348, 86%, 43%, 1);

$success: hsla(153, 53%, 53%, 1);
$success-dark: hsla(141, 53%, 31%, 1);

$warning: hsla(44, 100%, 77%, 1);
$warning-dark: hsla(23, 80%, 39%, 1);

$info-dark: hsla(207, 66%, 29%, 1);

$family-primary: 'Ubuntu';

$box-radius: 30px;

$gradient-background-color: linear-gradient(
  90deg,
  #ffffff 12.24%,
  #eef6fc 55.29%
);

$navbar-item-hover-background-color: $primary-light;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.875rem !default;
$size-8: 0.75rem !default;
$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 !default;

$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 1.25rem,
  '6': 1.5rem,
  '7': 1.75rem,
  '8': 2rem,
  '9': 3rem,
  '10': 4rem,
  'auto': auto,
) !default;

@mixin generate-gap-classes($gap-values) {
  @each $gap, $value in $gap-values {
    .gap-#{$gap} {
      gap: $value;
    }
  }
}
$gap-values: (
  1: 0.25rem,
  2: 0.375rem,
  3: 0.5rem,
  4: 0.625rem,
  5: 0.75rem,
  6: 1rem,
  7: 1.25rem,
  8: 1.5rem,
  9: 1.75rem,
  10: 2rem,
);

@include generate-gap-classes($gap-values);

$custom-colors: (
  'primary-dark': (
    $primary-dark,
    findColorInvert($primary-dark),
  ),
  'primary-light': (
    $primary-light,
    findColorInvert($primary-light),
  ),
  'danger-dark': (
    $danger-dark,
    findColorInvert($danger-dark),
  ),
  'success-dark': (
    $success-dark,
    findColorInvert($success-dark),
  ),
  'warning-dark': (
    $warning-dark,
    findColorInvert($warning-dark),
  ),
  'info-dark': (
    $info-dark,
    findColorInvert($info-dark),
  ),
);

$tabs-link-active-color: $primary;
$tabs-link-active-border-bottom-color: $primary;

$input-placeholder-color: hsla(0, 0%, 14%, 1);
