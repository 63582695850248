.expandButton {
  &:focus {
    box-shadow: none !important;
  }
}

.expandableRow {
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;

  &.expanded {
    animation: slideDown 0.4s forwards;
  }

  &.collapsed {
    animation: slideUp 0.4s forwards;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
    transform: translateY(-25%);
  }
  100% {
    max-height: 100px;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    max-height: 100px;
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    max-height: 0;
    opacity: 0;
    transform: translateY(-25%);
    display: none;
  }
}
