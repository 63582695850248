@import '../../../variables';

.container {
  background-color: transparent;
  padding: 30px;
  text-decoration: none !important;
  text-align: start;
}

.separator {
  border-right: 1px solid red;
}

.primaryIcon {
  height: 40px;
  width: 40px;
  background-color: $primary;
  border-radius: 50%;

  svg {
    height: 20px;
    width: 20px;
  }

  .span {
    padding: 12px 10px;
  }
}

.chevronIcon {
  svg {
    height: 20px;
    width: 20px;
  }
}

.title {
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $primary-dark;
    transition: width 0.2s ease-out;
  }

  &:hover::after {
    width: 100%;
  }
}

.titleContainer {
  position: relative;

  &:hover {
    .title {
      &::after {
        width: 100%;
      }
    }
  }
}
