@import '../../../../variables';

$border-radius: 8px;
$border-weigth: 1px;

::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: $border-radius;
}

::-webkit-scrollbar-track {
  border-top-right-radius: $border-radius;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table {
  vertical-align: middle;
  border-collapse: separate;

  &.tableBordered {
    border: solid $primary $border-weigth;
    border-radius: $border-radius;

    thead tr th:first-child {
      border-top-left-radius: $border-radius;
    }

    thead tr th:last-child {
      border-top-right-radius: $border-radius;
    }
  }

  th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 400;
  }

  th,
  td {
    vertical-align: middle;
  }

  a {
    text-decoration: underline;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  thead th {
    border-color: $primary;
    border-bottom-width: $border-weigth;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: $border-radius;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: $border-radius;
  }
}

.is-size-5 {
  width: 5%;
}

.is-size-10 {
  width: 10%;
}

.is-size-15 {
  width: 15%;
}

.is-size-20 {
  width: 20%;
}

.is-size-25 {
  width: 25%;
}

.is-size-30 {
  width: 30%;
}

.is-size-35 {
  width: 35%;
}

.is-size-40 {
  width: 40%;
}

.is-size-45 {
  width: 45%;
}

.is-size-50 {
  width: 50%;
}

.is-size-55 {
  width: 55%;
}

.is-size-60 {
  width: 60%;
}

.is-size-70 {
  width: 70%;
}

.is-size-80 {
  width: 80%;
}
