@import '../../../variables';

.field {
  position: relative;

  input {
    padding-right: 1.25rem;
    width: 60px;
  }
}

.field .button {
  position: absolute;
  right: 0;
  top: 55%;
  transform: translateY(-50%);
  z-index: 4;
  cursor: pointer;
  padding-right: 0.3rem;
  color: $primary;
}
