.checkboxContainer {
  position: relative;
  display: inline-block;
}

.checkboxContainer .tooltipContent {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  z-index: 1;
  margin-top: 5px;
  box-shadow: 0px 5px 5px 0px rgba(61, 70, 75, 0.3);
}

.checkboxContainer:hover .tooltipContent {
  visibility: visible;
  opacity: 1;
}
