@import '../../../variables';

.divider {
  margin-left: map-get($spacing-values, '5');
  width: 1px;
  background-color: #b5b5b5;
  height: 100%;
}

.pageContainer {
  min-width: 40px;
  text-align: center;
}

.paginatorContainer {
  border: 1px solid #b5b5b5;
  border-radius: 10px;
}
