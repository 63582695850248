@import '../../../variables';

.container {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
  padding-top: 1rem !important;
}

.layout {
  background: $gradient-background-color;
}
