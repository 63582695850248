$border-radius: 8px;

.container {
  border-collapse: separate;
  border-radius: $border-radius;
  display: flex;
  min-width: 5rem;
  min-height: 34px;
  padding: 6px 12px;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 12px;
}
