@import 'node_modules/bulma/sass/utilities/initial-variables.sass';
@import 'node_modules/bulma/sass/utilities/mixins.sass';
@import '../../../variables';

$border-radius: 8px;

.container {
  width: 100%;
  box-shadow: none;
  border: 1px solid $grey-light;
  border-radius: $border-radius;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.background {
  background-color: $primary-light;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

$responsive-breakpoint: 1700px;

.responsiveContainer {
  @include from($responsive-breakpoint) {
    width: 50%;

    &.rightContainer {
      justify-content: flex-end;
    }
  }

  @include until($responsive-breakpoint) {
    flex: none;
    width: 100%;

    &.rightContainer {
      justify-content: flex-start;
    }
  }
}
