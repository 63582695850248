@import 'node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../../../../variables';

.container {
  background-color: $white;
  cursor: pointer;

  &:hover {
    background-color: $primary-light;
  }

  &.active {
    background-color: $primary-dark;
    color: $white;
    cursor: unset;
  }
}
