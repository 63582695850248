@import '../../../../../variables';

.buttonContainer {
  position: absolute;
  right: 0;
  &:active,
  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.chevronDownActive {
  color: black;
}

.chevronDown {
  color: $primary-dark;
}
