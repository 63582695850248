@import '../../../variables';

.active {
  background-color: $primary-light !important;
}

.container {
  border-radius: 30px 30px 0px 0px !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.stepLabel {
  align-self: flex-start;
  padding-bottom: 0;
}

.taglessStep {
  padding-bottom: 12px;
}

.content {
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 1600px) {
  .content {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1600px) {
  .stepLabel {
    flex: none;
    width: unset;
    padding-bottom: 12px;
  }
}